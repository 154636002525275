import { AppPermissions } from "../../utils/consts";
import { GroupLink } from "./SidebarLinks";

type LinksMap = {
  events: GroupLink[];
  merch: GroupLink[];
  timeOff: GroupLink[];
  people: GroupLink[];
  settings: GroupLink[];
};
export const groupLinksMap: LinksMap = {
  events: [
    {
      path: "/achievements",
      linkKey: "navigation_eventsList",
    },
    {
      path: "/achievement-categories",
      linkKey: "navigation_eventCategories",
    },
    {
      path: "/achievements-applications",
      linkKey: "navigation_applicationsForEvents",
      permissions: [AppPermissions.AchievementEvents_ReviewAchievementEvents],
    },
    {
      path: "/manage-auto-events",
      linkKey: "navigation_manageAutoEvents",
      permissions: [AppPermissions.Gamification_ManageAutoEvents],
    },
    {
      path: "/events/archive",
      linkKey: "navigation_archiveEvents",
      permissions: [
        AppPermissions.Gamification_Achievements_ViewAchievementsArchive,
      ],
    },
  ],
  merch: [
    {
      path: "/merch-store",
      linkKey: "navigation_merchStore",
    },
    {
      path: "/merch-delivery",
      linkKey: "navigation_merchDelivery",
      permissions: [AppPermissions.MerchOrders_ViewAndManageOrders],
    },
    {
      path: "/merch-items-management",
      linkKey: "navigation_merchItemsManagement",
      permissions: [AppPermissions.Merch_ViewMerchDashboard],
    },
  ],
  timeOff: [
    {
      path: "/time-off-policy-types",
      linkKey: "navigation_policies",
      permissions: [AppPermissions.TimeOff_ViewAddEditDeletePolicies],
    },
    {
      path: "/time-off-requests",
      linkKey: "navigation_timeOffManagement",
      permissions: [AppPermissions.TimeOff_ViewAndManageOtherUsersRequests],
    },
    {
      path: "/time-off-calendar",
      linkKey: "navigation_timeOffCalendar",
      permissions: [AppPermissions.TimeOff_ViewCalendar],
    },
  ],
  people: [
    {
      path: "/org-structure",
      linkKey: "navigation_orgStructure",
    },
    {
      path: "/directory",
      linkKey: "navigation_directory",
    },
  ],
  settings: [
    {
      path: "/account-settings",
      linkKey: "navigation_accountSettings",
      permissions: [
        AppPermissions.AddEditRemoveCompanyMember,
        AppPermissions.ChangeCompanysLogoAndColors,
        AppPermissions.ViewAndEditCompanyData,
      ],
    },
    {
      path: "/system-settings",
      linkKey: "navigation_systemSettings",
      permissions: [AppPermissions.RolesManagement],
    },
    {
      path: "/group-management",
      linkKey: "navigation_groupManagement",
      permissions: [
        AppPermissions.UserGroupsManagement_ViewAddEditDeleteUserGroups,
      ],
    },
    {
      path: "/integrations",
      linkKey: "navigation_integrations",
      permissions: [AppPermissions.ManageIntegrations],
    },
    {
      path: "/company-management",
      linkKey: "navigation_companyManagement",
      permissions: [
        AppPermissions.ViewAndEditCompanyData,
        AppPermissions.ActivateDeactivateCompanys,
      ],
    },
  ],
};
