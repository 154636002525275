import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import accountReducer from "./reducers/accountReducer";
import authReducer from "./reducers/authReducer";
import notificationsReducer from "./reducers/notificationsReducer";
import companiesReducer from "./reducers/companiesReducer";
import manageBadgesReducer from "./reducers/manageBadgesReducer";
import newsReducer from "./reducers/newsReducer";
import employeeAchievementsReducer from "./reducers/employeePageReducers/employeeAchievementsReducer";
import systemSettingsReducer from "./reducers/systemSettingsReducer";
import analyticsReducer from "./reducers/analyticsReducer";
import orgStructureReducer from "./reducers/orgStructureReducer";
import directoryReducer from "./reducers/directoryReducer";
import merchStoreReducer from "./reducers/merchStoreReducer";
import merchDeliveryReducer from "./reducers/merchDeliveryReducer";
import merchItemsManagementReducer from "./reducers/merchItemsManagementReducer";
import achievementsReducer from "./reducers/achievementsReducer";
import achievementsEventsReducer from "./reducers/achievementsEventsReducer";
import playerProfileReducer from "./reducers/employeePageReducers/playerProfileReducer";
import leaderboardReducer from "./reducers/leaderboardReducer";
import achievementsAnalyticsReducer from "./reducers/achievementsAnalyticsReducer";
import employeePageReducer from "./reducers/employeePageReducers/employeePersonalInfoReducer";
import employeeDeliveryInfoReducer from "./reducers/employeePageReducers/employeeDeliveryInfoReducer";
import manageAutoEventsReducer from "./reducers/manageAutoEventsReducer";
import achievementsArchiveReducer from "./reducers/achievementsArchiveReducer";
import groupManagementReducer from "./reducers/groupManagementReducer";
import activityAnalyticsReducer from "./reducers/activityAnalyticsReducer";
import timeOffPolicyTypesReducer from "./reducers/timeOff/timeOffPolicyTypesReducer";
import timeOffPoliciesReducer from "./reducers/timeOff/timeOffPoliciesReducer";
import timeOffProfileReducer from "./reducers/employeePageReducers/timeOffProfileReducer";
import timeOffCalendarReducer from "./reducers/timeOff/timeOffCalendarReducer";
import timeOffRequestsReducer from "./reducers/timeOff/timeOffRequestsReducer";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    notifications: notificationsReducer,
    news: newsReducer,
    employeeAchievements: employeeAchievementsReducer,
    accountSettings: accountReducer,
    companies: companiesReducer,
    systemSettings: systemSettingsReducer,
    manageBadges: manageBadgesReducer,
    analytics: analyticsReducer,
    orgStructure: orgStructureReducer,
    directory: directoryReducer,
    merchStore: merchStoreReducer,
    merchDelivery: merchDeliveryReducer,
    merchItemsManagement: merchItemsManagementReducer,
    achievements: achievementsReducer,
    achievementsEvents: achievementsEventsReducer,
    playerProfile: playerProfileReducer,
    leaderboard: leaderboardReducer,
    achievementsAnalytics: achievementsAnalyticsReducer,
    employeePage: employeePageReducer,
    employeeDeliveryInfo: employeeDeliveryInfoReducer,
    manageAutoEvents: manageAutoEventsReducer,
    achievementsArchive: achievementsArchiveReducer,
    groupManagement: groupManagementReducer,
    activityAnalytics: activityAnalyticsReducer,
    timeOffPolicyTypes: timeOffPolicyTypesReducer,
    timeOffPolicies: timeOffPoliciesReducer,
    timeOffProfile: timeOffProfileReducer,
    timeOffCalendar: timeOffCalendarReducer,
    timeOffRequests: timeOffRequestsReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
