import { AxiosError } from "axios";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { loadBadgesList } from "../../../api/badgesApi";
import { UserOfSearch } from "../../../api/types/usersTypes";
import { getErrorMessage } from "../../../utils/methods";
import LayoutRightPanel from "../../layout/LayoutRightPanel";
import FeedbackModalFormTab from "./FeedbackModalFormTab";
import { refreshCoinsCount } from "../../../store/thunks/authThunks";
import { useAppDispatch } from "../../../store";
import { Features } from "../../../utils/consts";
import { Feedback } from "../../../api/types/employeePageTypes";
import { BadgeMessageSystem } from "../../../api/types/news";
import { useCompanyFeatureCheck } from "../../../utils/hooks";
import { BadgesDataState } from "api/types/badgesTypes";
import ModalBadgeTab from "utils/components/ModalBadgeTab";
import { fetchBadges } from "../../../utils/methods/fetchBadges";

type Props = {
  isModalOpen: boolean;
  onCloseModal: () => void;
  receiver?: UserOfSearch | null;
  feedback?: Feedback | null;
  badgeMessage?: BadgeMessageSystem | null;
};

const FeedbackRightPanel: FC<Props> = ({
  isModalOpen,
  onCloseModal,
  receiver,
  feedback,
  badgeMessage,
}) => {
  const { t } = useTranslation("employeeAchievements");
  const dispatch = useAppDispatch();
  const isGamificationAvailable = useCompanyFeatureCheck(Features.Gamification);

  const [tabIndex, setTabIndex] = useState(0);
  const initialBadgeData = {
    isLoading: false,
    badgeGroupsList: null,
    errorMessage: "",
  };
  const [badgeGroupsData, setBadgeGroupsData] =
    useState<BadgesDataState>(initialBadgeData);
  const [selectedBadgeId, setSelectedBadgeId] = useState<null | number>(null);
  const badgeImage = badgeGroupsData?.badgeGroupsList
    ?.flatMap((x) => x.badges)
    ?.find((badge) => badge.id === selectedBadgeId)?.imageUrl;

  useEffect(() => {
    if (isModalOpen) {
      fetchBadges(setBadgeGroupsData);
    }

    if (isGamificationAvailable) {
      dispatch(refreshCoinsCount());
    }

    if (badgeMessage) {
      setTabIndex(1);
      setSelectedBadgeId(badgeMessage.badgeId);
    }
  }, [isModalOpen]);

  const changeSelectedBadgeId = (id: number | null) => {
    setSelectedBadgeId(id);
  };

  const closeModal = () => {
    onCloseModal();
    setTabIndex(0);
    setBadgeGroupsData({
      isLoading: false,
      badgeGroupsList: null,
      errorMessage: "",
    });
    setSelectedBadgeId(null);
  };

  const onChangeTab = (index: number) => {
    setTabIndex(index);
  };

  const renderModalTitle = () => {
    if (!tabIndex) {
      return t("chooseBadgeTitle");
    }

    if (badgeMessage) {
      return t("action_editCheers");
    }

    return t("sendFeedbackTitle");
  };

  return (
    <LayoutRightPanel
      isModalOpen={isModalOpen}
      onCloseModal={closeModal}
      title={renderModalTitle()}
      additionalClassName={"popup-feedback popup-badge"}
    >
      <Tabs
        selectedIndex={tabIndex}
        onSelect={(index) => onChangeTab(index)}
        className="tabs"
      >
        <TabList className="d-none">
          {/* should be invisible, so no need in localization */}
          <Tab>Title 1</Tab>
          <Tab>Title 2</Tab>
        </TabList>

        <TabPanel>
          <ModalBadgeTab
            onChangeTab={onChangeTab}
            onCloseModal={closeModal}
            selectedBadgeId={selectedBadgeId}
            changeSelectedBadgeId={changeSelectedBadgeId}
            badgeGroupsData={badgeGroupsData}
          />
        </TabPanel>
        <TabPanel>
          <FeedbackModalFormTab
            onComeBack={onChangeTab}
            onCloseModal={closeModal}
            selectedBadgeImage={badgeImage}
            selectedBadgeId={selectedBadgeId}
            receiver={receiver}
            feedback={feedback}
            badgeMessage={badgeMessage}
          />
        </TabPanel>
      </Tabs>
    </LayoutRightPanel>
  );
};

export default FeedbackRightPanel;
