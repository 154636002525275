import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TimeOffCalendarUserDto } from "api/types/timeOff/timeOffCalendar";
import { defaultListState } from "appConsts";
import { TimeOffCalendarState } from "store/types";
import { logoutUserAction } from "../authReducer";
import { defaultCurrentMonthRange } from "pages/timeOff/timeOffCalendar/constsCalendar";
import { CustomSearchedFilters } from "utils/components/CustomFiltersForm";

// by default we display calendar for current month

const initialState: TimeOffCalendarState = {
  monthRange: defaultCurrentMonthRange,
  customFilters: null,
  searchUserQuery: null,
  timeOffsData: {
    ...defaultListState,
    page: 1,
    totalItems: 0,
  },
};

const timeOffCalendarSlice = createSlice({
  initialState,
  name: "timeOffCalendar",
  reducers: {
    setLoadingTimeOffsData: (state) => ({
      ...state,
      timeOffsData: {
        ...state.timeOffsData,
        isLoading: true,
        errorMessage: "",
      },
    }),
    setTimeOffsData: (
      state,
      action: PayloadAction<{
        usersTimeOffs: TimeOffCalendarUserDto[];
        totalItems: number;
      }>
    ) => {
      return {
        ...state,
        timeOffsData: {
          ...state.timeOffsData,
          isLoading: false,
          data: action.payload.usersTimeOffs,
          totalItems: action.payload.totalItems,
          errorMessage: "",
        },
      };
    },
    setErrorTimeOffsData: (state, action: PayloadAction<string>) => ({
      ...state,
      timeOffsData: {
        ...state.timeOffsData,
        isLoading: false,
        data: null,
        errorMessage: action.payload,
      },
    }),
    setCurrentCalendarPage: (state, action: PayloadAction<number>) => ({
      ...state,
      timeOffsData: {
        ...state.timeOffsData,
        page: action.payload,
      },
    }),
    setTimeOffCalendarDateRange: (
      state,
      action: PayloadAction<[string, string]>
    ) => ({
      ...state,
      monthRange: action.payload,
    }),
    setTimeOffCalendarFilters: (
      state,
      action: PayloadAction<CustomSearchedFilters>
    ) => ({
      ...state,
      customFilters: action.payload,
    }),
    setCalendarSearchUserQuery: (state, action: PayloadAction<string>) => ({
      ...state,
      searchUserQuery: action.payload,
    }),
    resetTimeOffsPage: (state) => ({
      ...state,
      timeOffsData: {
        ...state.timeOffsData,
        page: 1,
      },
    }),
    resetTimeOffCalendarState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(logoutUserAction, () => initialState);
  },
});

export default timeOffCalendarSlice.reducer;

export const {
  setLoadingTimeOffsData,
  setTimeOffsData,
  setErrorTimeOffsData,
  setTimeOffCalendarDateRange,
  setTimeOffCalendarFilters,
  setCalendarSearchUserQuery,
  setCurrentCalendarPage,
  resetTimeOffsPage,
  resetTimeOffCalendarState,
} = timeOffCalendarSlice.actions;
