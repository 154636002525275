import {
  AnalyticsDateRange,
  ExportFormat,
  PageDto,
  QueryParams,
} from "./commonTypes";

export type ActivityInsightsDto = {
  activeUsers: {
    activeUsersPercent: number;
    monthlyActiveUsersCount: number;
  };
  totalUsers: {
    activatedUsersPercent: number;
    totalUsersCount: number;
  };
  emojiReactions: {
    emojiReactionsCount: number;
    eventsCount: number;
    badgeMessageCount: number;
  };
};

export type ActivityMostActiveUsersParams = QueryParams & AnalyticsDateRange;

export type ActivityMostActiveUsersItemDto = {
  userId: number | null;
  firstName: string | null;
  familyName: string | null;
  avatarImageUrl: string | null;
  isOnline: boolean;
  position: string | null;
  reactionsCount: number | null;
  achievementsCount: number | null;
};

export type ResponseActivityMostActiveUsers = {
  items: ActivityMostActiveUsersItemDto[];
};

export enum ActivityAnalyticsActiveUsersSortBy {
  ReactionsCount = "ReactionsCount",
  AchievementsCount = "AchievementsCount",
}

type ExportActivityMostActiveUsersSortingOptions = {
  orderBy: ActivityAnalyticsActiveUsersSortBy;
  isDescending: boolean;
};

export type ExportActivityMostActiveUsersDataRequest = {
  exportFormat: ExportFormat;
  sorting: ExportActivityMostActiveUsersSortingOptions;
  dateRange?: AnalyticsDateRange;
};

export enum PeopleOverTimeMainFilters {
  position = "Position",
  location = "Location",
  department = "Department",
  manager = "Manager",
  // member = "Member", // need clarification
}

export type PeopleOverTimeQueryParams = QueryParams &
  AnalyticsDateRange & {
    groupBy: PeopleOverTimeMainFilters;
  };

export type PeopleOverTimeItemDto = {
  groupColumn: string | null;
  invitedCount: number | null;
  activatedCount: number | null;
  monthlyActiveCount: number | null;
  reactionsCount: number | null;
};

export type ResponseActivityPeopleOverTime = PageDto<PeopleOverTimeItemDto>;

export enum ActivityAnalyticsPeopleOverTimeSortBy {
  ReactionsCount = "ReactionsCount",
  InvitedCount = "InvitedCount",
  ActivatedCount = "ActivatedCount",
  MonthlyActiveCount = "MonthlyActiveCount",
}

type ExportActivityPeopleOverTimeSortingOptions = {
  orderBy: ActivityAnalyticsPeopleOverTimeSortBy;
  isDescending: boolean;
};

export type ExportActivityPeopleOverTimeDataRequest = {
  exportFormat: ExportFormat;
  sorting: ExportActivityPeopleOverTimeSortingOptions;
  groupBy: PeopleOverTimeMainFilters;
  dateRange?: AnalyticsDateRange;
};
