import { AchievementDto } from "api/types/achievementTypes";
import { User } from "api/types/usersTypes";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Calendar, Tooltip, Whisper } from "rsuite";

import { useAppSelector } from "store";
import { Icon } from "utils/components";
import { getAvatarSign, getFullName } from "utils/methods";
import { getCropTitle } from "utils/methods/getCropTitle";
import UserAvatar from "../../utils/components/UserAvatar";
import { parseDateFromString } from "../../utils/methods/parseDateFromString";

export const NewsCalendar: FC = () => {
  const { t } = useTranslation("news");
  const { data: myTeamEvents } = useAppSelector(
    (state) => state.news.myTeamEventsByMonth
  );
  const { data: events } = useAppSelector(
    (state) => state.news.eventsEndingByMonth
  );

  const getListAllEvents = (date: Date) => {
    const birthdayUsers =
      myTeamEvents &&
      myTeamEvents.filter((event: User) => {
        if (!event.birthDate) return null;

        const eventDate = parseDateFromString(event.birthDate);
        return (
          date.getDate() === eventDate.getDate() &&
          date.getMonth() === eventDate.getMonth()
        );
      });

    const hireDayUsers =
      myTeamEvents &&
      myTeamEvents.filter((event: User) => {
        if (!event.hireDate) return null;

        const eventDate = parseDateFromString(event.hireDate);
        return (
          date.getDate() === eventDate.getDate() &&
          date.getMonth() === eventDate.getMonth()
        );
      });

    const eventsEnding =
      events &&
      events.filter((event: AchievementDto) => {
        if (!event.activeUntil) return null;

        const eventDate = parseDateFromString(event.activeUntil);
        return (
          date.getDate() === eventDate.getDate() &&
          date.getMonth() === eventDate.getMonth()
        );
      });

    return { birthdayUsers, hireDayUsers, eventsEnding };
  };

  const renderUserList = (title: string, users: User[]) => {
    const titleImg = title === "birthdays" ? "gift" : "star";
    return (
      users &&
      users.length > 0 && (
        <>
          <div className="newsCalendarTooltip__title-block">
            <Icon href={`#${titleImg}`} svgClass={`ic-${titleImg}`} />
            <h3 className="newsCalendarTooltip__small-title">{t(title)}</h3>
          </div>
          {users.map((user: User) => {
            const avatarSign = getAvatarSign(
              user?.firstName,
              user?.familyName,
              ""
            );
            const fullName = getFullName(user?.firstName, user?.familyName);
            return (
              <Link
                to={`/employee/${user.id}`}
                key={user.id}
                className="newsCalendarTooltip__userCard"
              >
                <UserAvatar
                  avatarImageUrl={user.avatarImageUrl}
                  avatarSign={avatarSign}
                  className="user-avatar newsCalendarTooltip__userAvatar"
                />
                <div className="newsCalendarTooltip__text">{fullName}</div>
              </Link>
            );
          })}
        </>
      )
    );
  };

  const renderTooltip = (
    birthdayUsers: User[] | null,
    hireDayUsers: User[] | null,
    eventsEnding: AchievementDto[] | null
  ) => (
    <Tooltip className="custom-tooltip  newsCalendarTooltip newsCalendarTooltip--min-width">
      {birthdayUsers && renderUserList("birthdays", birthdayUsers)}
      {hireDayUsers && renderUserList("anniversaries", hireDayUsers)}
      {eventsEnding && eventsEnding?.length > 0 && (
        <>
          <div className="newsCalendarTooltip__title-block">
            <Icon href="#events" svgClass="ic-events" />
            <h3 className="newsCalendarTooltip__small-title">{t("events")}</h3>
          </div>
          {eventsEnding.map((event: AchievementDto) => {
            return (
              <Link
                to={`/achievements/${event.id}`}
                key={event.id}
                className="newsCalendarTooltip__userCard"
              >
                <div className="newsCalendarTooltip__text">
                  {getCropTitle(event.name, 38)}
                </div>
              </Link>
            );
          })}
        </>
      )}
    </Tooltip>
  );

  const renderCell = (date: Date) => {
    const { birthdayUsers, hireDayUsers, eventsEnding } =
      getListAllEvents(date);
    if (
      (birthdayUsers && birthdayUsers.length > 0) ||
      (hireDayUsers && hireDayUsers.length > 0) ||
      (eventsEnding && eventsEnding.length > 0)
    ) {
      return (
        <>
          <Whisper
            speaker={renderTooltip(birthdayUsers, hireDayUsers, eventsEnding)}
            placement="bottom"
            trigger="hover"
            delay={800}
            enterable
          >
            <div className="newsCalendar__tooltip-block" />
          </Whisper>
        </>
      );
    }

    return null;
  };
  const getCalendarCellClassName = (date: Date) => {
    const { birthdayUsers, hireDayUsers, eventsEnding } =
      getListAllEvents(date);

    let result = "";

    if (
      (birthdayUsers && birthdayUsers.length > 0) ||
      (hireDayUsers && hireDayUsers.length > 0) ||
      (eventsEnding && eventsEnding.length > 0)
    ) {
      result = "oneDay";
    }

    return result;
  };

  return (
    <div className="achievementTimeline newsCalendar">
      <h1 className="main-title hidden-mobile">{t("calendar")}</h1>
      <div className="box achievementSingleCalendar newsCalendar__content">
        <Calendar
          compact
          isoWeek
          locale={{ formattedMonthPattern: "LLLL yyyy" }}
          value={new Date()}
          renderCell={renderCell}
          cellClassName={getCalendarCellClassName}
        />
      </div>
    </div>
  );
};
