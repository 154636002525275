import { OptionNumberValue } from "utils/types";
import { BasicUserDto } from "./usersTypes";

export type SearchRequest = {
  query: string;
};

export enum ExportFormat {
  Csv = "Csv",
  MicrosoftExcel = "MicrosoftExcel",
}

export enum SortDirections {
  asc = "asc",
  desc = "desc",
}

export type ListDto<TItem> = {
  items: TItem[];
};

export type PageDto<TItem> = {
  items: TItem[];
  pageNumber: number;
  totalItems: number;
};

export type OdataDTO<TItem> = {
  items: TItem[];
  totalItemCount: number;
};

export type TotalItems = {
  totalItems: number;
}

export type Placement =
  | "bottomStart"
  | "bottomEnd"
  | "topStart"
  | "topEnd"
  | "leftStart"
  | "leftEnd"
  | "rightStart"
  | "rightEnd"
  | "auto"
  | "autoVerticalStart"
  | "autoVerticalEnd"
  | "autoHorizontalStart"
  | "autoHorizontalEnd";

export type UploadImage = {
  id?: number;
  fileUrl: string;
  file?: Blob;
};

export type QueryParams = {
  searchQuery?: string | null;
  pageNumber?: number;
  pageSize?: number;
  orderBy?: string;
  isDescending?: boolean;
};

export type DateRange = {
  dateFrom?: Date;
  dateTo?: Date;
};

export type AnalyticsDateRange = {
  fromDate?: Date | string;
  toDate?: Date | string;
};

export type ClientDateRange = [string, string] | null;

export enum Month {
  January = "January",
  February = "February",
  March = "March",
  April = "April",
  May = "May",
  June = "June",
  July = "July",
  August = "August",
  September = "September",
  October = "October",
  November = "November",
  December = "December",
}

type PrivacySettingsRequestDto = null | {
  userIds: number[];
  userGroupIds: number[];
};

export type PrivacySettingsRequest = {
  isPrivate: boolean;
  privacySettings: PrivacySettingsRequestDto;
};

export type PrivacySettingsDto = {
  users: null | BasicUserDto[];
  groupIds: null | number[];
};

export type PrivacySettingsValues = {
  userIds: OptionNumberValue[];
  userGroupIds: number[];
};

export enum AppliesToOptions {
  public = "public",
  private = "private",
}

export enum AllStatusesFilter {
  all = "",
}
